<template>

  <div class="page" id="Fade">
    <h1>Fade</h1>
  </div>
</template>

<script>


export default {
  name: "Fade",

}
</script>

<style scoped>

</style>
